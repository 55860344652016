<template>
    <b-input-group>
        <b-form-input
            id="config_name"
            type="text"
            placeholder="Name your config... (Christmas colors, Black style, Summer colors)"
            :value="value"
            v-on:input="(inputData) => $emit('input', inputData)"
        />
        <b-input-group-append>
            <b-button-group>
                <b-button variant="success" v-if="!nameExists"  @click="$emit('addConfig', 0)">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>

                <b-button variant="warning" v-if="!nameExists" @click="$emit('addConfig', 1)">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save and set as active</span>
                </b-button>

                <b-button variant="primary" v-if="nameExists" @click="$emit('replaceConfig',0)">
                    <feather-icon
                        icon="RepeatIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Replace</span>
                </b-button>

                <b-button variant="danger" v-if="nameExists"  @click="$emit('replaceConfig',1)">
                    <feather-icon
                        icon="RepeatIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Replace and set active</span>
                </b-button>

            </b-button-group>

        </b-input-group-append>
    </b-input-group>
</template>
<script>
    import {BButton, BButtonGroup, BFormInput, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'

    export default {
        props: {
            value: {
                type: String,
                required: true,
                default: ''
            },
            nameExists: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: {
            BButton,
            BFormInput,
            BInputGroup,
            BInputGroupAppend,
            BButtonGroup
        }
    }
</script>
